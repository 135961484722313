import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import 'minireset.css/minireset.css';

import './index.css';
import App from './App';
import { config } from 'utils';
// import * as serviceWorker from './serviceWorker';

if (config.env === 'production') {
  Sentry.init({
    dsn: config.sentry,
    env: config.env,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// serviceWorker.unregister();
