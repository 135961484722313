import { gql } from '@apollo/client';

export const ProjectFragment = gql`
  fragment projectFragment on Project {
    name
    thumbnail
    description
    body {
      ... on ProjectBodyVideo {
        type
        label
        primary {
          video_url
        }
      }
      ... on ProjectBodySlideshow {
        type
        label
        fields {
          image
        }
      }
    }
    client {
      ... on Client {
        name
      }
    }
    categories {
      category {
        ... on Categorie {
          name
          _meta {
            uid
          }
        }
      }
    }
    _meta {
      id
      uid
    }
  }
`;
