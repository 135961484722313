import { useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { RichText as PrismicRichText } from 'prismic-reactjs';

import './index.css';
import Head from 'components/Head';
import { initOverlay } from './overlay';
import Categories from 'components/Categories';
import { aboutQuery } from 'queries';

const About = () => {
  const [isOpen, setOpen] = useState(false);
  const { data } = useQuery(aboutQuery);

  useEffect(() => {
    initOverlay();
    const initAbout = setTimeout(() => {
      setOpen(true);
    }, 500);

    return () => {
      clearTimeout(initAbout);
      setOpen(false);
    };
  }, []);

  return (
    <>
      <Head title="About" url="/about" />
      <CSSTransition in={isOpen} timeout={500} classNames="about" unmountOnExit>
        <div className="about view">
          <Categories wrapper />
          {data?.allAbouts.edges[0] && (
            <div className="about__wrapper">
              <div className="about__info">
                <div className="about__description">
                  <PrismicRichText render={data.allAbouts.edges[0].node.description} />
                </div>
              </div>
              <div className="about__more">
                <h2 className="about__subtitle">Festival, Press &amp; Diffusion</h2>
                <div className="about__festival">
                  <PrismicRichText render={data.allAbouts.edges[0].node.festival} />
                </div>
              </div>
            </div>
          )}
        </div>
      </CSSTransition>
      <svg className="shape-overlays" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path className="shape-overlays__path" />
      </svg>
    </>
  );
};

export default About;
