import { gql } from '@apollo/client';

export const aboutQuery = gql`
  {
    allAbouts {
      edges {
        node {
          description
          festival
        }
      }
    }
  }
`;
