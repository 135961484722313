import { gql } from '@apollo/client';

import { ProjectFragment } from './fragments';

export const projectQuery = gql`
  ${ProjectFragment}

  query Project($uid: String!) {
    project(uid: $uid, lang: "en-us") {
      ...projectFragment
    }
  }
`;

export const allProjectsQuery = gql`
  ${ProjectFragment}

  {
    allHomepages {
      edges {
        node {
          projects {
            project {
              ...projectFragment
            }
          }
        }
      }
    }
  }
`;
