import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import './Slideshow.css';
import { useInterval } from 'utils';

const Slideshow = ({ data }) => {
  const [slide, setSlide] = useState(0);
  console.log(data);
  useInterval(() => {
    const newSlide = slide < data.length - 1 ? slide + 1 : 0;
    setSlide(newSlide);
  }, 5000);

  return (
    <div className="slideshow">
      <div className="slideshow__container">
        {data.map((project, index) => (
          <CSSTransition
            in={slide === index}
            timeout={500}
            classNames="slide"
            key={index}
            unmountOnExit
          >
            <Link to={`/project/${project.project._meta.uid}`} className="slideshow__link">
              <div
                className="slideshow__project"
                style={{
                  backgroundImage: `url(${project.project.thumbnail.url})`,
                }}
              >
                <div className="slideshow__titles">
                  <h1 className="slideshow__title">{project.catchphrase || project.name}</h1>
                </div>
                <div className="slideshow__subtitles">
                  <h2 className="slideshow__client">{project.project.client?.name || ''}</h2>
                  <p className="slideshow__categories">
                    {project.project.categories.reduce((result, current, index) => {
                      if (index === 0) {
                        return `${current.category.name}`;
                      }

                      return `${result}\u00A0+\u00A0${current.category.name}`;
                    }, '')}
                  </p>
                </div>
              </div>
            </Link>
          </CSSTransition>
        ))}
        <div className="slideshow__arrows">
          <span
            className="slideshow__arrow"
            onClick={() => {
              const newSlide = slide - 1 < 0 ? data.length - 1 : slide - 1;
              setSlide(newSlide);
            }}
          >
            &#8592;
          </span>
          <span
            className="slideshow__arrow"
            onClick={() => {
              const newSlide = slide + 1 > data.length - 1 ? 0 : slide + 1;
              setSlide(newSlide);
            }}
          >
            &#8594;
          </span>
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
