import { gql } from '@apollo/client';
import { ProjectFragment } from './fragments';

export const homepageQuery = gql`
  ${ProjectFragment}

  {
    allHomepages {
      edges {
        node {
          slideshow {
            project {
              ...projectFragment
            }
            catchphrase
          }
          projects {
            project {
              ...projectFragment
            }
          }
        }
      }
    }
  }
`;
