import React from 'react';
import { useQuery } from '@apollo/client';
import { Link, Redirect } from 'react-router-dom';
import { RichText as PrismicRichText } from 'prismic-reactjs';

import './index.css';
import Video from './Video';
import Head from 'components/Head';
import Slideshow from './Slideshow';
import Categories from 'components/Categories';
import { projectQuery, allProjectsQuery } from 'queries';

const Project = ({
  match: {
    params: { projectReference },
  },
}) => {
  const { loading, data } = useQuery(projectQuery, { variables: { uid: projectReference } });
  const { data: allProjects } = useQuery(allProjectsQuery);

  if (loading) return null;

  if (!data.project) {
    return <Redirect to="/not-found" />;
  }

  const currentProject =
    allProjects?.allHomepages.edges[0] &&
    allProjects?.allHomepages.edges[0].node.projects.findIndex(
      project => project.project._meta.uid === projectReference,
    );
  const nextProject =
    (allProjects?.allHomepages.edges[0] &&
      allProjects?.allHomepages.edges[0].node.projects[currentProject + 1]) ||
    null;

  return (
    <>
      <Head
        title={data.project.name}
        description={PrismicRichText.asText(data.project.description)}
        image={data.project.thumbnail.url}
        url={`/project/${data.project._meta.uid}`}
      />
      <div className="project__page view">
        <Categories wrapper />
        {data.project.body[0].type === 'video' ? (
          <Video video={data.project.body[0].primary.video_url} />
        ) : (
          <Slideshow data={data.project.body[0].fields || []} />
        )}
        <div className="project">
          <h1 className="project__title">{data.project.name}</h1>
          <div className="project__description">
            <PrismicRichText render={data.project.description} />
          </div>
          {nextProject && (
            <Link className="project__next" to={`/project/${nextProject.project._meta.uid}`}>
              next project
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default Project;
