import ApolloClient from 'apollo-client';
import { PrismicLink } from 'apollo-link-prismic';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import fragmentTypes from './fragmentTypes.json';

const apiEndpoint = 'https://corentinkopp.cdn.prismic.io/graphql';
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes,
});

export const client = new ApolloClient({
  link: PrismicLink({ uri: apiEndpoint }),
  cache: new InMemoryCache({ fragmentMatcher }),
});
