import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from 'routes/Home';
import About from 'routes/About';
import Project from 'routes/Project';
import Layout from 'components/Layout';
import NotFound from 'routes/NotFound';
import ErrorBoundary from 'components/ErrorBoundary';
import { client } from 'utils';

const App = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ErrorBoundary>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/category/:categoryReference" component={Home} />
            <Route exact path="/project/:projectReference" component={Project} />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </ErrorBoundary>
    </BrowserRouter>
  </ApolloProvider>
);

export default App;
