import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { LiquidDistortionText } from 'react-text-fun';

import './index.css';
import Head from '../Head';

const Layout = ({ children, location }) => {
  const [hoverContact, setHoverContact] = useState(false);
  const isAbout = location.pathname === '/about';

  return (
    <>
      <Head title="Home" url="/" />
      <header className="header">
        <div
          className={classNames('header__item header__item--logo', {
            'header__item--logo--hide': isAbout,
          })}
        >
          <Link className="header__logo" to="/">
            <span>Corentin</span>
            <span>Kopp</span>
          </Link>
        </div>
        <div
          className={classNames('header__item header__item--contact', {
            'header__item--contact--relative': isAbout,
          })}
          onMouseEnter={() => setHoverContact(true)}
          onMouseLeave={() => setHoverContact(false)}
        >
          <Link className="header__contact" to={isAbout ? '/' : '/about'}>
            <div className="header__contant--mobile">
              {isAbout ? <span className="cross" /> : <span>hi!</span>}
            </div>
            <div className="header__contact--tablet">
              <LiquidDistortionText
                text="hello,"
                fontSize={18}
                fontFamily="'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
              'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif"
                fontWeight={500}
                speed={0.6}
                volatility={hoverContact ? 0.07 : 0}
                fill="#000000"
              />
              <LiquidDistortionText
                text="contact"
                fontSize={18}
                fontFamily="'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
              'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif"
                fontWeight={500}
                speed={0.6}
                volatility={hoverContact ? 0.07 : 0}
                fill="#000000"
              />
              <LiquidDistortionText
                text="me!"
                fontSize={18}
                fontFamily="'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
              'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif"
                fontWeight={500}
                speed={0.6}
                volatility={hoverContact ? 0.07 : 0}
                fill="#000000"
              />
            </div>
          </Link>
        </div>
      </header>
      <main>{children}</main>
      {!isAbout && (
        <footer className="footer">
          <a
            href="https://www.instagram.com/cokopp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            @cokopp
          </a>
          <a
            href="https://vimeo.com/koppcorentin"
            target="_blank"
            rel="noopener noreferrer"
          >
            vimeo.com/koppcorentin
          </a>
        </footer>
      )}
    </>
  );
};

export default withRouter(Layout);
