import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { replace, capitalize } from 'lodash';

import './index.css';
import Head from 'components/Head';
import Slideshow from './Slideshow';
import Categories from 'components/Categories';
import { homepageQuery } from 'queries';

const Home = ({
  match: {
    params: { categoryReference = null },
  },
}) => {
  const { loading, error, data } = useQuery(homepageQuery);

  if (loading || error) return null;

  return (
    <>
      <Head
        title={categoryReference ? replace(capitalize(categoryReference), '-', ' ') : 'Home'}
        url={categoryReference ? `/category/${categoryReference}` : '/'}
      />

      <div className="home">
        {data.allHomepages.edges[0].node.slideshow.length > 0 && (
          <Slideshow data={data.allHomepages.edges[0].node.slideshow} />
        )}
        <Categories />
        <section className="project__list">
          {data.allHomepages.edges[0].node.projects
            .filter(project => {
              if (!categoryReference) return true;

              return Boolean(
                categoryReference &&
                  project.project.categories.some(
                    category => category.category._meta.uid === categoryReference,
                  ),
              );
            })
            .map((project, index) => (
              <article className="project__newwrapper" key={index}>
                <Link to={`/project/${project.project._meta.uid}`}>
                  <div
                    className="project__wrapper"
                    style={{
                      backgroundImage: `url(${project.project.thumbnail.url})`,
                    }}
                  />
                  <div className="project__container">
                    <h1 className="project__client">{project.project.client?.name || ''}</h1>
                    <p className="project__categories">
                      {project.project.categories.reduce((result, current, index) => {
                        if (index === 0) {
                          return `${current.category.name}`;
                        }

                        return `${result}\u00A0+\u00A0${current.category.name}`;
                      }, '')}
                    </p>
                  </div>
                </Link>
              </article>
            ))}
        </section>
      </div>
    </>
  );
};

export default Home;
