import Carousel from 'nuka-carousel';
import debounce from 'lodash/debounce';
import React, { useState, useEffect } from 'react';

const Slideshow = ({ data }) => {
  const windowWith = window.innerWidth;
  const [slideWidth, setSlideWidth] = useState(windowWith - (windowWith > 900 ? 375 : 0));
  const [cellSpacing, setCellSpacing] = useState(windowWith > 900 ? 50 : 0);

  useEffect(() => {
    const handleResize = debounce(() => {
      const width = window.innerWidth;
      setSlideWidth(width - (width > 900 ? 375 : 0));
      setCellSpacing(windowWith > 900 ? 50 : 0);
    }, 1000);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <Carousel
      cellAlign="center"
      cellSpacing={cellSpacing}
      length={data.length}
      slideIndex={0}
      slidesToShow={cellSpacing === 0 ? 1 : 2}
      slideWidth={`${slideWidth}px`}
      speed={1000}
      transitionMode="scroll"
      wrapAround={true}
      renderCenterLeftControls={({ previousSlide }) => (
        <div className="project__slide--prev" onClick={previousSlide} />
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <div className="project__slide--next" onClick={nextSlide} />
      )}
    >
      {data.map((slideshow, index) => (
        <div key={index}>
          <div
            className="project__slide"
            style={{
              backgroundImage: `url(${slideshow.image.url})`,
            }}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default Slideshow;
