import React from 'react';
import ReactPlayer from 'react-player';

const Video = ({ video }) => (
  <div className="project__video">
    <div className="project__video--container">
      <ReactPlayer
        url={video.embed_url}
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </div>
  </div>
);

export default Video;
