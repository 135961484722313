import React from 'react';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';

import './index.css';
import { categoriesQuery } from 'queries';

const Categories = ({ wrapper = false }) => {
  const { loading, error, data } = useQuery(categoriesQuery);

  if (loading || error) return null;

  return (
    <nav className={classNames('category', { 'category--wrapper': wrapper })}>
      <NavLink exact activeClassName="category__item--active" className="category__item" to="/">
        all project
      </NavLink>

      {data.allCategories.edges.map((category, index) => (
        <NavLink
          activeClassName="category__item--active"
          className="category__item"
          to={`/category/${category.node._meta.uid}`}
          key={index}
        >
          {category.node.name}
        </NavLink>
      ))}
    </nav>
  );
};

export default Categories;
