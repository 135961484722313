import React from 'react';
import { truncate } from 'lodash';
import { Helmet } from 'react-helmet';

import { config } from 'utils';
import SeoCK from 'images/corentin-kopp.jpg';

const DEFAULT_DESCRIPTION =
  'Hello, I’m a Belgian Director and Director of Photography. I’m living and working in Brussels (BE) but have strong links, both personals and professionals with Berlin (DE) & Stockholm (SE)';

const Head = ({
  title,
  description = DEFAULT_DESCRIPTION,
  image = null,
  url,
}) => {
  const transformedDescription = truncate(description.replace(/<[^>]+>/g, ''), {
    length: 300,
  });

  const seoImage = image
    ? `${config.apiImage}small/${image}`
    : `${config.domain}${SeoCK}`;

  return (
    <Helmet>
      <title>{title} | Corentin Kopp</title>
      <meta name="description" content={transformedDescription} />
      <meta itemprop="name" content={`${title} | Corentin Kopp`} />
      <meta itemprop="description" content={transformedDescription} />
      <meta itemprop="image" content={seoImage} />

      <meta property="og:url" content={`${config.domain}${url}`} />
      <meta property="og:title" content={`${title} | Corentin Kopp`} />
      <meta property="og:site_name" content="Corentin Kopp" />
      <meta property="og:description" content={transformedDescription} />
      <meta property="og:image" content={seoImage} />
    </Helmet>
  );
};

export default Head;
