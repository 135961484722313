import { gql } from '@apollo/client';

export const categoriesQuery = gql`
  {
    allCategories(sortBy: position_ASC) {
      edges {
        node {
          name
          _meta {
            id
            uid
          }
        }
      }
    }
  }
`;
